<template>
  <BoardIndex
      :board_title="title"
      :stage_order_offset="offset"
      :stage_order_limit="limit"
      :team_permission="permission"
  />
</template>

<script>

import BoardIndex from "@/components/applications/BoardIndex";

export default {
  name: "ApplicationsBoard",
  components: {
    BoardIndex
  },
  data() {
    return {
      title: 'Admissions',
      permission: 'view applications',
      offset: 0,
      limit: 10
    };
  },

};
</script>

