<template>
  <div>
    <CampusHeader
        :can-create="false"
        :activeTab="activeTab"
        :manage-campuses="true"
        @close="close"
        @change-tab="changeTab"
        :header-text="'New Application'"
        :close-button="inModal"
        :header_tabs="tabs"
        :hide-tabs="true"
        :campus="active_campus"

    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  :destroy-on-hide="true" @input="changeTab" :class="{'hidden-tabs':tabs.length<2}" v-model="activeTab">
        <b-tab-item v-if="useTab('guardian')" label="Guardian">
          <div class=" columns is-centered">
            <div class="column is-10 ">
              <div class="columns">
                <div class="column">
                  <UsersFilter
                      :role="'guardian'"
                      :selected_user_id="guardian?guardian.id:null"
                      :type="'autocomplete'"
                      @selected="selectGuardian"
                      @cleared="$emit('guardian_cleared')"
                      :campus_id="$store.state.campus.selected_campus_id"
                  ></UsersFilter>
                </div>
                <div v-if="canCreateGuardian" class="column is-narrow">
                  <b-tooltip
                      :label="`Create a new Guardian`"
                      size="is-small"
                      type="is-success"
                      multilined
                  >
                    <a
                        class="card-header-icon has-text-primary px-0 py-0"
                        @click.prevent.stop="newGuardian=true"
                    >
                      <b-icon :icon="$tc('icons.create')" size="is-medium"/>
                    </a>

                  </b-tooltip>
                </div>
              </div>
              <transition mode="out-in" name="fade">
                <UserCreateForm
                    v-if="newGuardian"
                    :canCreate="canCreateGuardian"
                    :canDelete="false"
                    :canEdit="false"
                    @user-created="selectGuardian"
                    :default-role="'guardian'"
                    :type="'Guardian'"
                    :edit="false"
                    :in-modal="false"
                ></UserCreateForm>
              </transition>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('wards')" label="Student">
          <div class=" columns is-centered">
            <div class="column is-10">
              <WardsPanel
                  :guardian-mode="guardianMode"
                  :canCreate="canCreateWard"
                  :can-create-new="canCreateLearner"
                  :canDelete="false"
                  :userObject="guardian"
                  :can-select="true"
                  @selected="selectLearner"
              ></WardsPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('details')" label="Application Details">
          <div class=" columns is-centered">
            <div class="column is-10">
              <form @submit.prevent="submit">
                <b-field grouped group-multiline>
                  <b-field label="Phase" expanded>
                    <b-select :disabled="!newApplication.campus_id" required expanded placeholder="Phase"
                              v-model="newApplication.phase_id">
                      <option
                          v-for="phase in phases"
                          :key="phase.id"
                          :value="phase.id"
                      >{{ phase.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Stage" expanded>
                    <b-select :disabled="!newApplication.phase_id" required expanded placeholder="Stage"
                              v-model="newApplication.stage_id">
                      <option
                          v-for="stage in stages"
                          :key="stage.id"
                          :value="stage.id"
                      >{{ stage.name }}
                      </option>
                    </b-select>
                  </b-field>

                </b-field>
                <b-field grouped>
                  <b-field expanded label="Month">
                    <b-select required expanded placeholder="Month" v-model="newApplication.month">
                      <option :value="1">January</option>
                      <option :value="2">February</option>
                      <option :value="3">March</option>
                      <option :value="4">April</option>
                      <option :value="5">May</option>
                      <option :value="6">June</option>
                      <option :value="7">July</option>
                      <option :value="8">August</option>
                      <option :value="9">September</option>
                      <option :value="10">October</option>
                      <option :value="11">November</option>
                      <option :value="12">December</option>
                    </b-select>
                  </b-field>
                  <b-field expanded label="Year">
                    <b-select required expanded v-model="newApplication.year" placeholder="Year">
                      <option
                          v-for="year of $store.getters['dates/application_years']"
                          :key="year + 'year'"
                          :value="year"
                      >{{ year }}
                      </option>
                    </b-select>

                  </b-field>
                </b-field>


                <b-field label="Payment Responsibility">
                  <b-input v-model="newApplication.payment_responsibility"></b-input>
                </b-field>
                <b-field label="Message">
                  <b-input v-model="newApplication.message" type="textarea"></b-input>
                </b-field>
                <b-field grouped position="is-centered" class="mt-5">
                  <div class="control">
                    <b-button
                        :loading="loading"
                        native-type="submit"
                        tag="input"
                        type="is-primary"
                        value="Save"
                    >
                    </b-button>
                  </div>

                </b-field>
              </form>
            </div>
          </div>
        </b-tab-item>

      </b-tabs>
    </section>
  </div>
</template>

<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";
import UserCreateForm from "@/components/users/UserCreateForm";
import {mapState} from "vuex";
import Campus from "@/models/Campus";
import WardsPanel from "@/components/users/WardsPanel";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import Application from "@/models/Application";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "ApplicationForm",
  components: {
    CampusHeader,
    UserCreateForm,
    WardsPanel, UsersFilter

  },
  data() {
    return {
      activeTab: 0,

      newApplication: {
        campus_id: this.$store.state.campus.selected_campus_id,
        phase_id: null,
        stage_id: null,
        year: new Date().getFullYear(),
        month: null,
        message: null,
        guardian_id: this.defaultGuardian ? this.defaultGuardian.id : null,
        user_id: null,
        status_id: 1,
        application_stage_id: 1,
        payment_responsibility: ''
      },
      loading: false,
      loaded: false,
      guardian: this.defaultGuardian,
      learner: null,
      newGuardian: false,
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },
  props: {
    guardianMode: {
      type: Boolean, default() {
        return false
      }
    },
    inModal: {
      type: Boolean,
      default() {
        return true
      }
    },
    defaultGuardian: {
      type: Object,
      default() {
        return null
      }
    },
    tabs: {
      type: Array, default() {
        return [{title: 'Guardian', role: 'guardian'}, {title: this.$tc('Learner',1), role: 'wards'}, {
          title: 'Application Details',
          role: 'details'
        }]
      }
    },
    canCreateSubject: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateLearner: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateWard: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditWard: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteWard: {
      type: Boolean,
      default() {
        return false;
      },
    },

  },
  methods: {
    submit() {
      if (!this.newApplication.guardian_id) {
        this.$store.dispatch('toast/createToast', {message: 'Please select a guardian'})
        return
      }
      if (!this.newApplication.user_id) {
        this.$store.dispatch('toast/createToast', {message: `Please select a ${this.$tc('learner',1)}`})
        return
      }
      this.loading = true
      Application.Store(this.newApplication).then(result => {
        this.loading = false
        this.$buefy.snackbar.open('New application created!')
        this.$emit('application-created', result.entities.applications[0].id)
        this.$emit('close')
      }).catch(
          err => {
            this.loading = false
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )
    },
    selectPhase(phase) {
      this.newApplication.phase_id = phase.id
    }, selectStage(stage) {
      this.newApplication.stage_id = stage.id
    },
    selectLearner(learner) {
      this.newApplication.user_id = learner.id

      setTimeout(() => this.activeTab = 2, 300)

    },
    selectGuardian(guardian) {

      if (this.newApplication.guardian_id) {
        if (this.newApplication.guardian_id !== guardian.id) {

          this.guardian = guardian
          this.newApplication.guardian_id = guardian.id
          setTimeout(() => this.activeTab = 1, 300)
        }
      } else {
        this.guardian = guardian
        this.newApplication.guardian_id = guardian.id
        setTimeout(() => this.activeTab = 1, 300)
      }

    },
    changeTab(index) {
      if (index === 1) {
        if (this.newApplication.guardian_id) {
          this.activeTab = index;
        } else {
          return
        }
      }
      this.activeTab = index;
    },
    handleDelete() {
      this.$emit("deleted");
    },
    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },
    close() {
      this.$emit("close");
    },
  },

  computed: {
    active_campus() {
      return Campus.query().whereId(this.$store.state.campus.selected_campus_id).first()
    },
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where('campus_id', this.newApplication.campus_id).where('year',this.newApplication.year).get()
    },
    stages() {
      return Stage.query().where('campus_id', this.newApplication.campus_id).where('year',this.newApplication.year).where('phase_id', this.newApplication.phase_id).get()
    },

    ...mapState("campus", ["selected_user"]),


  },
  mounted() {
    Campus.FetchById(this.$store.state.campus.selected_campus_id, ['phases', 'phases.stages'])
    if (this.inModal) {
      this.makeModalLarge()
    }
  },
};
</script>
